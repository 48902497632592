.reports-data {
  .value-text {
    color: #1565c0;
    font-size: 36px;
  }
}

.reports-chart {
  width: 99%;
}

.user-avatar-reports {
  width: 35px;
  height: 35px;
}


.rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner, .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover {
  background-color: #3498ff;
  width: 2px;
}

.rs-picker-menu.rs-picker-check-menu, .rs-picker-menu.rs-picker-select-menu {
  z-index: 10000;
}
.scheduledContainer {
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 100%;
      max-width: 200px;
    }
  }
}

.checkoutContainer {
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    max-width: 500px;
  }


}

.spinner-status-client {
  font-size: 60px;
  margin-bottom: 30px;
}

.message-alert {
  background-color: #1565C0;
  max-width: 500px;
  border-radius: 10px;
  padding: 41px 14px 25px;

  p {
    color: white;
  }
}

.iconAlert {
  position: relative;
  top: 37.5px;
  left: 50%;
  margin-left: -37.5px;
  height: 73px;
  width: 73px;
  border-radius: 50%;
  border: 3px solid #1565C0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 40px;
    margin-top: -5px;
  }
}


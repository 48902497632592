.videoHeader {
  background-color: #323232;
  color:white;
  .time-container {
    display: flex;
    flex-direction: row;
    color: white;
    border-radius: 10px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.11);
    width: 100px;
  }
  .closeVideoConferenceButton {
    color: white;
  }
  .btn-fullScreen {
    color: white;
    font-size: 12px;
  }

  .btn-fullScreen:hover {
    color: dodgerblue;
    text-decoration: none;
  }

  .btn-close:hover {
    color: #a71d2a;
    text-decoration: none;
  }
  .dropdown-toggle{
    color: white !important;
    border-color: white !important;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    color: white !important;
    background-color: rgba(255, 255, 255, 0.22);
    border-color: white !important;
  }

  .btn-outline-primary:hover {
    background-color: rgba(255, 255, 255, 0.22) !important;
    color: white !important;
  }
  .btn-outline-primary:focus {
    background-color: rgba(255, 255, 255, 0.22) !important;
    color: white !important;
  }
}

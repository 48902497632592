@import 'variables';

.phone-validate-wrapper {
  overflow-y: hidden;
  & .left-half {

    .button-link {
      background-color: $primary-color;
    }
    footer {
      text-align: center;
      font-size: 10px;
    }

    ::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $primary-color;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $primary-color;
    }

    .register-form {
      overflow-y: auto;
    }
  }

  & span.error {
    color: red;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    font-family: Roboto;
  }

  & .right-half {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: url("../../images/register.jpg") no-repeat left;
    background-clip: border-box;
  }
}

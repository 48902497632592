@import '../styles/variables';

.edit-icon {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.line-separator {
  width: 100%;
  height: 2px;
  margin: 0 0 12px;
  border: solid 2px rgba(var(--color-secondary), 0.5);
}

.labelColor {
  min-width: 120px;
}

.branding-color {
  background-color: gray;
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.team-form-wrapper {
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  border-radius: 5px;
  padding: 5px;
}

.branding-color-box {
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
}


.branding-form {
  img.image-branding {

    max-width: 250px;
    min-width: 250px;
    border: thin dashed #b9b9b9;
    border-radius: 5px;
    display: block;
    height: 90px;
    margin: 0 auto;
    padding: 5px;
  }

  section {

    .drop-input {
      box-shadow: none;
    }
    label {
      font-family: Roboto, serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: rgba(var(--color-primary), 1.0);
      cursor: pointer;
      margin-top: 10px;
    }
  }
}

.branding {

  .logoBrandingContainer {
    text-align: center;
    border-radius: 5px;
    border: thin solid red;
    .logo {
      max-width: 200px;
    }


  }

}

p.error {
  color: red;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.43;
  font-family: Roboto, serif;
}

.roles {

  .rol-item {
    // border-bottom: 1px $shadow-color solid;
    font-size: 12px;

    .user-avatar {
      width: 35px;
      height: 35px;
    }

    .rol-cancel-subscription {
      font-size: 12px;
    }
  }
  .button-create-user {

    img {
      width: 20px;
      margin-right: 10px;
    }
  }
}


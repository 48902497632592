@import 'variables';

.license-type-wrapper {

  .payment-list {

    font-family: Roboto, serif;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;

    li {
      width: 240px;
      cursor: pointer;
    }

    button {
      width: 100%;
      text-transform: inherit;

      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    li {
      @media screen and (min-width: 600px) {
        &:not(:first-child) {
          margin: 0 0 0 15px;
        }
      }
    }

    .card {
      border-radius: 20px;
      border: solid 4px $primary-color;
      text-align: center;
      height: 100%;

      -webkit-box-shadow: 0px 18px 11px -6px rgba(0,0,0,0.3);
      box-shadow: 0px 18px 11px -6px rgba(0,0,0,0.3);

      &.active {
        border: solid 4px red;
      }

      &:hover {
        border: solid 4px $primary-color;

        background:  $primary-color;
        background: -moz-linear-gradient(top, $primary-color 0%, $primary-color 60%, rgba(21, 101, 192, 0.6) 100%);
        background: -webkit-linear-gradient(top, $primary-color 0%, $primary-color 60%, rgba(21, 101, 192, 0.6) 100%);
        background: linear-gradient(to bottom, $primary-color 0%, $primary-color 60%, rgba(21, 101, 192, 0.6) 100%);

        .card-text, .price, h5, .price-footer {
          color: white;
        }

      }

      .card-text {
        font-size: 12px;
        line-height: 1.8;
        color: #4a4a4a;
      }

      .price {
        font-size: 42px;
        font-weight: bold;
        color: $primary-color;
        display: block;
        margin-top: 3vh;
      }

      .price-footer {

        font-size: 12px;
        color: #4a4a4a;
        margin: 0 0 15px 0;
      }

      .card-header {
        background-color: transparent;
        padding: 20px 15px 0;
        border: 0;
        margin-bottom: 2vh;
        font-size: 24px;
        font-weight: bold;
        color: #4a4a4a;
      }
    }
  }

  .custom-breadcrumb {

    .breadcrumb {
      margin: 0;
      padding: 0;
      background-color: transparent;
    }

    .breadcrumb > li + li:before {
      padding: 0 3px 0 3px;
      color: #ccc;
      content: "\003e";

    }
  }

  &.half {

    .button-link {
      background-color: $primary-color;
    }

    footer {
      text-align: center;
      font-size: 10px;
    }


  }

  & span.error {
    color: red;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    font-family: Roboto, serif;
  }

}


.sidebar {
  height: 100vh;
  z-index: 10;
  overflow: hidden;
  background-color: $sidebar-color;

  .sidebar-wrapper {
    width: 100%;
    margin: 8px 8px 0 8px;

    .nav {
      li:first-child {
        padding-bottom: 10px;
      }
      li:last-child {
        padding-top: 10px;
        //border-top: thin solid $shadow-color;
        text-align: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        .btn:hover {
          color: rgba(var(--color-primary), 1);
          background-color: rgba(var(--color-primary), 0.5);
          border-color: rgba(var(--color-primary), 1);
        }
      }
      li {
        //margin: 5px 0 5px 0;
        padding: 0;
        font-size: 14px;
        span {
          margin-left: 10px;
        }
        .icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(var(--color-primary), 1);
          border-radius: 50%;
          width: 60px;
          height: 60px;

          div.new-appointment {
            width: 30px;
            height: 30px;
            background-size: cover;
            background-image: url('../../images/menu-icons/new-appointment.svg');
          }

        }

        a.icon {
          margin: 5px 10px 5px 10px;
        }

        .nav-link {
          margin: 0;
          padding: 0;
          color: $text-color;

          &:hover {
            color:  rgba(var(--color-primary), 1);
          }
        }

        &.active {
          background-color: rgba(var(--color-primary), 0.1);
          border-radius: 5px;
          span {
            margin-left: 10px !important;
            font-weight: bold;
            color: rgba(var(--color-primary), 1.0) !important;
          }
        }
      }
    }
  }

}

@media (min-width: 576px) {
  .sidebar {
    display: none;

    &.show {
      display: block !important;
      position: absolute;
      z-index: 99;
    }
  }
}

@media (min-width: 768px) {

  .mobile-menu {
    display: none;
  }
  .sidebar {
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
    width: 80px;

    .sidebar-wrapper {
      width: 100%;
      .nav{

        li:last-child {
          p {
            font-size: 12px;
          }
          .btn {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
          }
          img {
            margin:0;
          }
        }
        li {

          a.icon {
            margin-left: 20px;
          }
        }
        span {

          display: none;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .sidebar {
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;

    width: 200px;
    .sidebar-wrapper {
      width: 100%;
      .nav{
        li {

        }
        span {

          display: block;
        }

        li:last-child {
          p {
            font-size: 14px;
          }
          .btn {
            width: auto;

          }
          img {
            margin:0;
          }
        }

      }
    }
  }
  icon {
    margin: 0 ;
  }
}

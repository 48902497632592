@import '../styles/variables';

.table-appointments {

  .pagination-wrap .pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin: 0 auto;
    padding: 0;
    list-style: none;
    border-radius: 0.25rem;
  }

  .pagination-wrap .pagination .page-item {
    margin-top: 10px;

  }

  .disabled {
    color: #d0d0d0 !important;
  }
  .btn.btn-primary[disabled] {
    background-color: #d0d0d0;
  }
  .no-disabled {

  }
  .appointments-counter {
    font-size: 12px;
  }

  .nodata {
    margin-left: 10px;
    margin-top: 20px;
  }

  .table-sort-header {
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0 10px 0;
  }

  .table-item-appointment {
    font-size: 12px;
    p {
      margin: 0;
      padding:0;
    }
    .doctor-name {
      font-size: 12px;
      font-weight: bold;
    }
    .doctor-email {
      font-size: 10px;
    }


    &:last-child {
      /* border-bottom: none; */
    }

    .user-avatar {
      width: 35px;
      height: 35px;
    }

    .actions {
      color: $primary-color;
    }

    button.btn-init {
    }

    a {
      cursor: pointer;
    }

    .paid {
      color: #00c853;
      margin-right: 5px;
    }

    .notpaid {
      color:firebrick;
      margin-right: 5px;
    }

    .status {
      font-size: 10px;
      color: white;
      padding: 4px 10px 4px 10px;
      border-radius: 5px;

      &.pending {
        background-color: $status-color-pending;
      }

      &.cancelled {
        background-color: $status-color-cancelled;
      }

      &.in-progress {
        background-color: $status-color-in-progress;
        animation: blinker 1s linear infinite;
      }

      &.expired {
        background-color: $status-color-expired;
      }

      &.pending-payment {
        background-color: $status-color-pending-payment;
      }

      &.finished {
        background-color: $status-color-finished;
      }

      &.unknown {
        background-color: $status-unknown;
      }

    }
  }
}

.footer-label {
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.noresults-container {
  border-top: thin #bababa solid;
  width: 100%;
  text-align: center;
  padding: 40px;

  p {
    margin: 10px 0 0 0;
    padding:0;
    color: gray;
  }
}

.spinner-container {
  border-top: thin #bababa solid;
  width: 100%;
  text-align: center;
  padding: 40px;
}

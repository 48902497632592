@import 'variables';

.register-wrapper {

  & .left-half {

    .button-link {
      background-color: $primary-color;
      min-width: 120px;
    }
    footer {
      text-align: center;
      font-size: 10px;
    }
    .logo-company-auth {
      max-height: 50px;
    }
  }

  & span.error {
    color: red;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    font-family: Roboto;
  }

  & .right-half {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: url("../../images/register.jpg") no-repeat left;
    background-clip: border-box;
  }
}

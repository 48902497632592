@import 'variables';

.receipt-wrapper {

  & .half {

    .button-link {
      background-color: $primary-color;
    }
    footer {
      text-align: center;
      font-size: 10px;
    }
  }

  & span.error {
    color: red;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    font-family: Roboto;
  }

}


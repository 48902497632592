.videoFooter {
  z-index: 100;
  .footerWrapper {
    background-color: #323232;
    height: 100%;
    .wrapper-buttons {
      width: 120px;
    }

    .wrapper-mobile-buttons {
      width: 60px;
    }

    button {
      vertical-align: middle;
    }

    img {
      height: 35px;
    }

    span {
      font-size: 12px;
    }

    .circle {
      border-radius: 50%;
      height: 60px;
      width: 60px;
    }

    .hang {
      background-color: #d50000;
    }

    .leave {
      background-color: forestgreen;

      img {
        height: 25px;
        width: 25px;
      }
    }
  }


}

body {
  overflow: hidden !important;
}

.view-container {

}
.main-panel {
  background-color: $white-bg;
  .btn-primary {
    background-color: $primary-color;
  }

  .main-content {

  }
  .page-header {
    margin-top: 25px;
    .btn-nueva-cita {
      min-width: 160px;
    }
    .page-title {
      font-weight: bold;
    }
  }

}

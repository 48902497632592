.modal-title {
  font-size: 30px;
  font-weight: bold;
  font-family: Roboto;
}
.modal-icon {
  fill: rgba(var(--color-primary), 1.0);
}
.select-option-placeholder {
  span {
    color:rgba(var(--color-primary), 1.0);
  }
}

.icon-success {
  color: #19bb47;
}

.icon-error {
  color: red;
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  color: red;
}

.success-message {
  width: 100%;
  margin-top: 0.25rem;
  color: #19bb47;
}

.timePicker {
  .react-time-picker__wrapper {
    border:none;
  }
}

.spinner-container {
  border-top: none;
  width: 100%;
  text-align: center;
}
.body-size {
  min-height: 200px;
}
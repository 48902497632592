@import 'variables';

.configure-account-wrapper {

  .button-link {
    background-color: $primary-color;
  }

  .form-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 150px;
  }

  .drop-container {

    width: 150px;
    height: 150px;
    background: #d8d8d8;
    border-radius: 50%;

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;

    }
    section {
      position: relative;
      top: -25px;
      .section-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .drop-input {
        box-shadow: none;
      }
      .dropzone-placeholder {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: $primary-color;
        border: 3px white solid;
        border-radius: 50%;
        .edit-icon-avatar {
          color:white;
          font-size: 15px;
        }
      }

      label {
        font-family: Roboto, serif;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #1565c0;
        cursor: pointer;
        margin-top: 10px;
      }


    }
  }

  .custom-breadcrumb {

    .breadcrumb {
      margin: 0;
      padding: 0;
      background-color: transparent;
    }

    .breadcrumb > li + li:before {
      padding: 0 8px 0 0px;
      color: #ccc;
      content: "\003e";

    }
  }

  .half {

    footer {
      text-align: center;
      font-size: 10px;
    }


  }

  span.error {
    color: red;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    font-family: Roboto, serif;
  }

}


.notfound {
  font-family: Roboto;
  color: #bdbdbd;

  h1 {
    font-size: 100px;
    font-weight: lighter;
  }

  h5 {
    font-size: 30px;
    font-weight: lighter;
  }
}
@import 'variables';
body {
  overflow: auto;
}

.experience-wrapper {
  overflow: auto;



  .custom-breadcrumb {

    .breadcrumb {
      margin: 0;
      padding: 0;
      background-color: transparent;
    }

    .breadcrumb > li + li:before {
      padding: 0 8px 0 0px;
      color: #ccc;
      content: "\003e";

    }
  }

  .half2 {

    .button-link {
      background-color: $primary-color;
    }
    footer {
      text-align: center;
      font-size: 10px;
    }
  }

  span.error {
    color: red;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    font-family: Roboto;
  }

  .form-group {


  }

}

a.custom-tab {
  text-decoration: none;
  color: $text-color;
  padding-bottom: 10px;
  &.active {
    border-bottom: rgba(var(--color-primary), 1) solid 3px;
    color: rgba(var(--color-primary), 1);
  }
  &:hover {
    color: $text-color;
    border-bottom: rgba(var(--color-primary), 0.5) solid 3px;

  }
}

.card-header-citas {
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: rgba(var(--color-primary), 0.2);
  color: white;

  &::after {
    content: url(../../images/icons/caret-down.png);
    color: white;

    right: 10px;
    position: absolute;
  }

  &[aria-expanded="true"]::after {
    content: url(../../images/icons/caret-up.png);
  }

  .citas-counter {

  }

  &:hover {
    color: white;
    background-color: rgba(var(--color-primary), 1.0);
  }
  &.collapsed {
    background:rgba(var(--color-primary), 0.8);
  }
  &:not(.collapsed) {
    background:rgba(var(--color-primary), 1.0);
  }
}

.separator {
  margin-bottom: 5px;
  margin-top: 5px;
  height: 1px;
  width: 100%;
  background-color: #eeeeee;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .separator2 {
    display: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .separator2 {
    display: none;
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .separator2 {
    display: none;
  }
}

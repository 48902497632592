.consult-content {
  width: 50%;
  max-width: 420px;
  position: absolute;
  min-width: 325px;
  top: 45px;
  z-index: 9999999;
  left: 5px;

  .closeVideoConsultation {
    cursor: pointer;
  }
  ul {
    padding: 0;
  }

  ul > li {
    font-size: 0.8rem;
    list-style: none;
  }
}

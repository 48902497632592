@import 'variables';

.branding-wrapper {

  .button-link {
    background-color: $primary-color;
  }

  & .half {
    text-align: center;

    .form-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      height: calc(75vh - 30px);
      @media (max-width: 768px) {
        height: calc(100vh - 90px - 20px);
      }
    }

    footer {
      text-align: center;
      font-size: 10px;
    }

    .square-form {

      img.image-branding {
        background-color: #f1f1f1;
        max-width: 250px;
        min-width: 250px;
        border: thin solid #bdbdbd;
        display: block;
        height: 90px;
        margin: 0 auto;
      }
      section {
        .drop-input {
          box-shadow: none;
        }
        label {
          font-family: Roboto, serif;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: center;
          color: #1565c0;
          cursor: pointer;
          margin-top: 10px;
        }
      }
    }
  }

  span.error {
    color: red;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    font-family: Roboto, serif;
  }



}


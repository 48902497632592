@import '../styles/variables';

.user-avatar {
  width: 80px;
  height: 80px;
  border: $shadow-color thin solid;
}

.header-container-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.remove-item-list {
  color: red;
  width: 50px;
}

.add-item-list {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: larger;
}

.profile-data {

  padding-bottom: 10px;
  .profile-data-1 {
    color: $text-color;

    p {
      margin: 0;

      &.name {
        font-size: 16px;
      }

      &.specialist {
        font-size: 12px;
      }

      &.prescriptions {
        margin-top: 10px;
        color:  rgba(var(--color-primary), 1);
        font-size: 12px;

        .checkIcon {
          font-size: 16px;
        }
      }
    }
    .npi-dea {
      margin-top: 10px;
    }
  }
  .profile-data-2 {
    color: $text-color;
    p {
      margin: 0 0 5px;
    }
    .checkIcon {
      margin-right: 10px;
    }
  }
}

.profile-biography {
  border-top: thin $shadow-color solid;
  padding-top: 10px;
}
.resume-profile {
  .edit-icon {
    font-size: 14px;
  }
  .save-button-formation {
    right: 0;
    top: 0;
  }
  p {
    margin: 0px;
  }
  .item-dates{
    color: lighten($text-color, 40);
  }
}

.error1{
  color:red !important;
}
.stripebuttons{
  margin-right: 5px;
}
.stripeprods{
  margin-bottom: 10px;
  box-shadow: 0 1em 1em -1em rgba(0, 0, 0, .25);
   padding:0 0 30px 0;
}
.stripeprods::before{
 content:attr(data-before-content);
padding-left: 5px;
}
.row.stripeprods:nth-child(even) {background-color: #f0f6ff; border-radius: 10px;

}
.stripebuttonsuser{
  margin: 0 auto;

}

section {
  position: relative;
  top: -15px;
  .section-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .drop-input {
    box-shadow: none;
  }
  .dropzone-placeholder {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: $primary-color;
    border: 3px white solid;
    border-radius: 50%;
    .edit-icon-avatar {
      color:white;
      font-size: 15px;
    }
  }

  label {
    font-family: Roboto, serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #1565c0;
    cursor: pointer;
    margin-top: 10px;
  }
}



// Main
$white-bg:        #FFFFFF !default;
$sidebar-width:   360px;
$text-color:      #4a4a4a;
$primary-color:   #1565c0;

// SIdeBar
$sidebar-color:   #FAFAFC;
// NavBar
$shadow-color: rgba(31, 31, 31, 0.2);
$shadow-sidebar-color: rgba(26, 26, 26, 0.6);
$shadow-size:     3px !default;
$navBar-height:   93px;

//Appointments status color

$status-color-pending:              #00bf31;
$status-color-pending-payment:      #1565c0;
$status-color-in-progress:          #00bf31;
$status-color-cancelled:            #d50000;
$status-color-expired: #acacac;
$status-color-finished:             #000000;
$status-unknown:                    #a8a8a8;

// SideBar
$color-sidebar-item-selection:  rgba(21, 101, 192, 0.2);

/*body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: fixed;
  inset: 0;
  -webkit-text-size-adjust: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}*/

.alertWrapper {
  width: 100%;
  height: 100vh;
  display: none;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  .alertLabel {
    padding: 20px 20px;
    height: 100px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    text-align: center;
    font-size: medium;

    img {
      margin-bottom: 30px;
    }
  }
}

@media screen and (orientation: landscape) {
  .videoMobileContainer {

  }

  .alertWrapper {
    z-index: 1000;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;


    .alertLabel {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .iconMobileRotation {
        height: 80px;
      }
    }
  }
}

bodyContainer {
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: fixed;
  inset: 0;
  -webkit-text-size-adjust: 100%;
  padding: env(safe-area-inset-top, 20px)
          env(safe-area-inset-right, 20px)
          env(safe-area-inset-bottom, 20px)
          env(safe-area-inset-left, 20px);
}

.BadConnectionWrapper {
  text-align: left;
  margin-left: 10px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 100000;
  font-size: 26px;
  .signal {
    height: 40px;
  }
}

.StatsDataWrapper {
  text-align: left;
  margin-left: 10px;
  margin-top: 20px;
  width: 250px;
  position: absolute;
  top: 50px;
  z-index: 100000;
  background-color: rgba(92, 124, 143, 0.29);
  backdrop-filter: blur(10px);
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
}
.statsData {
  width: 100%;
  text-align: left;

  p {
    color: black;
    margin: 0;
    padding: 0;
  }
}

.badConnectionAlert {
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  background-color: rgba(213, 0, 0, 0.9);
  padding: 10px;
}

.badConnectionWarning2 {
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  background-color: rgba(249, 168, 37, 0.9);
  padding: 10px;
}

.badConnectionWarning1 {
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  background-color: rgb(242, 234, 36, 0.9);
  padding: 10px;
}

.badConnectionWarning0 {
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  background-color: rgba(21, 101, 192, 0.9);
  padding: 10px;
}

.badConnectionSuccess {
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  background-color: rgba(0, 200, 83, 0.9);
  padding: 10px;
}

.badConnectionDisabled {
  display: inline-block;
  color: rgba(255, 255, 255, 0.49);
  border-radius: 5px;
  background-color: rgba(114, 114, 114, 0.38);
  padding: 10px;
}

@keyframes blinkingBackground{
  0%		{ background-color: red;}
  50%	    { background-color: red;}
  0%		{ background-color: red;}
}

.spinnerContainer {
  height: 100vh;
  width: 100vw;
  .spinner {
    color: dodgerblue;
  }
}
.fixedLocalVideo {

  position: absolute;
  top:0;
  left:0;
  width: 100%;
  //border: 3px solid blue;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  .videoItem {
    display: flex;
    justify-items: center;
    align-items: center;
    border-radius: 10px;
    -moz-border-radius:10px;
    -webkit-border-radius: 10px;
    -webkit-background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.43);
    border: 3px rgba(0, 0, 0, 0.43) solid;
    overflow:hidden;
    min-height: 80px;
    max-height: 120px;
    video.video-stream-local {
      z-index: 99;
      min-height: 80px;
      max-height: 120px;
      border-radius: 10px;
      -moz-border-radius:10px;
      -webkit-border-radius: 10px;
      object-fit: cover;
    }
  }
}

.videoMobileLayout {

  .rightSideBarContent {
    height: 100%;
    max-height: 100%;
    position: fixed;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .soapContainer {
      min-height: 100vh;
      max-height: 100vh;
      .soapLayout {
        display: grid;
        grid-template-rows: 50px 1fr 80px;
        background-color: #eee;
        min-height: 100%;
        height: 100%;
        width: 100%;
        max-height: 100%;
        .soapHeader {
          min-height: 30px;
          span {
            padding-left: 10px;
            font-size: 20px;
            font-weight: bold;
          }
        }
        .documentContainer {
          margin: 10px 10px 0px 10px;
          .soapField {
            background-color: #fafafa;
            height: 100%;
            width: 100%;
            padding: 10px;
            border: none;
            border-radius: 10px;
            border-color: #c9c9c9;
            resize: none;
            /* width */
            &::-webkit-scrollbar {
              width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #cccccc;
              border-radius: 4px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #a4a4a4;
            }
          }
        }
        .soapFooter {
          padding: 10px;
          .saved {

            font-size: 12px;
          }
        }
      }
    }
    .chatContainer {
      min-height: 100%;
      max-height: 100%;
      .chatLayout {
        display: grid;
        grid-template-rows: 50px 1fr 80px;
        background-color: #eee;
        min-height: 100%;
        height: 100%;
        width: 100%;
        max-height: 100%;
      }
    }
  }

  .videoMobileContainer {
    position: fixed;
    bottom: 0;
    display: grid;
    grid-template-columns: 10fr;
    grid-template-rows: 1fr;
    min-height: 100%;
    min-width: 100vw;
    max-height: 100%;
    .mainContent {
      min-height: 100%;
      max-height: 100%;
      display: grid;
      grid-template-rows: 20px 1fr 80px;
      .videoContent {
        position: relative;
        top: 0;
        left: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        background-color:black;
        overflow: hidden;
        .videoItem {
          border-radius: 5px;
          -moz-border-radius:5px;
          -webkit-border-radius: 5px;
          -webkit-background-clip: padding-box;
          background-color: rgba(0, 0, 0, 0.43);
          border: 2px rgba(0, 0, 0, 0.43) solid;
          overflow:hidden;
          height: 100px;
          min-height: 80px;
          max-height: 150px;
          video.video-stream-local {
            z-index: 99;
            height: 100px;
            min-height: 80px;
            max-height: 150px;
            border-radius: 5px;
            -moz-border-radius:5px;
            -webkit-border-radius: 5px;
            object-fit: cover;
          }
        }
        .videoMainItem {
          position:relative;
          top:0;
          left:0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          video.video-stream-remote {
            align-items: center;
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
          .infoWrapper {
            position: absolute;
            width: 100%;
            height:100%;
            .statusRemoteAlert {
              background-color: green;
              width: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .BadConnectionWrapper {
              top: 12px;
              .signal {
                padding: 7px !important;
                height: 30px;
              }
            }
            .StatsDataWrapper {
              margin-top: 5px;
            }
            .audioStatusIcon {
              position: absolute;
              top: 0px;
              right: 10px;
              margin-right: 10px;
              margin-top: 17px;
              background: rgba(255, 0, 0, 0.3); // Make sure this color has an opacity of less than 1
              backdrop-filter: blur(8px);
              border-radius: 50%;
              height: 30px;
              width: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              .checkIcon {
                color: white;
                font-size: 15px;
              }
            }
            .labelName {
              position: absolute;
              top: 20px;
              left: 50px;
              font-size: 10px;
              padding: 5px 10px;
              background: rgba(217, 217, 217, 0.75); // Make sure this color has an opacity of less than 1
              backdrop-filter: blur(8px);
              border-radius:15px;
              color: black;
            }
            .statsName {
              position: absolute;
              top: 20px;
              left: 100px;
              padding: 10px 20px;
              background: rgba(217, 217, 217, 0.75); // Make sure this color has an opacity of less than 1
              backdrop-filter: blur(8px);
              border-radius:15px;
              color: black;
            }
            .videoStatusIcon {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .videoIcon {
                font-size: 3rem;
              }
            }
          }
        }

      }
      .m {
        grid-template-columns: repeat(1, 1fr) !important;
        grid-template-rows:  repeat(1, 1fr)  !important;
      }
      .m1 {
        grid-template-columns: repeat(1, 1fr) !important;
        grid-template-rows:  repeat(1, 1fr)  !important;

      }
      .m2 {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-template-rows:  repeat(2, 1fr)  !important;
        .videoMainItemLocal {
          grid-column-start: 1 !important;
          grid-column-end: -1 !important;

        }
      }
      .m3 {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-template-rows:  repeat(2, 1fr)  !important;
        max-height: 100%;
        .videoMainItemLocal {
          grid-column-start: auto;
          grid-column-end: auto;
        }
      }
      .m4 {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-template-rows:  repeat(3, 1fr)  !important;
        max-height: 100%;
        .videoMainItemLocal {
          grid-column-start: auto;
          grid-column-end: auto;
        }
      }
      .m5 {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-template-rows:  repeat(3, 1fr)  !important;
        max-height: 100%;
        .videoMainItemLocal {
          grid-column-start: auto;
          grid-column-end: auto;
        }
      }
      .m6 .m7 .m8 {
        grid-template-columns: repeat(3, 1fr) !important;
        grid-template-rows:  repeat(3, 1fr)  !important;
        max-height: 100%;
        .videoMainItemLocal {
          grid-column-start: auto;
          grid-column-end: auto;
        }
      }

      .m9 .m10 .m11 {
        grid-template-columns: repeat(3, 1fr) !important;
        grid-template-rows:  repeat(4, 1fr)  !important;
        max-height: 100%;
        .videoMainItemLocal {
          grid-column-start: auto;
          grid-column-end: auto;
        }
      }
    }
  }
}

.chatHeader {
  min-height: 30px;
  span {
    padding-left: 10px;
    font-size: 20px;
    font-weight: bold;
  }
}
.chatHistory {
  height: 100%;
  background-color: #fafafa;
  overflow-x: hidden !important;
  overflow-y: auto;
  border-radius: 10px;
  margin: 10px 10px 10px 10px;
  padding: 10px;
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #a4a4a4;
  }


  .msg-bubble {
    margin-bottom: 10px;
  }

  .msg-info {
    color: #4a4a4a;
    font-size: 0.8em;
  }

  .msg-text {
    border-radius: 3px;
    color: #4a4a4a;
    font-size: 0.9em;
    max-width: 80%;
    min-width: 50%;
    padding: 5px 5px 5px 5px;
    text-align: left;

    p {
      border-radius: 1.15rem;
      line-height: 1.25;
      padding: 0.5rem .875rem;
      position: relative;
      word-wrap: break-word;
    }
  }

  .msg.right-msg {
    .msg-bubble {
      .msg-text {
        p {

          background-color: #248bf5;
          color: #fff;
        }

        p::before {
          border-bottom-left-radius: 0.8rem 0.7rem;
          border-right: 1rem solid #248bf5;
          right: -0.35rem;
          transform: translate(0, -0.1rem);
        }
        p::after {
          background-color: #fff;
          border-bottom-left-radius: 0.5rem;
          right: -40px;
          transform: translate(-30px, -2px);
          width: 10px;
        }

        p::before,
        p::after {
          bottom: -0.1rem;
          content: "";
          height: 1rem;
          position: absolute;
        }
      }
    }
  }

  .msg.left-msg {
    .msg-bubble {
      .msg-text {
        p {
          background-color: #e5e5ea;
          color: #000;
        }

        p::before {
          border-bottom-right-radius: 0.8rem 0.7rem;
          border-left: 1rem solid #e5e5ea;
          left: -0.35rem;
          transform: translate(0, -0.1rem);
        }

        p::after {
          background-color: #fff;
          border-bottom-right-radius: 0.5rem;
          left: 20px;
          transform: translate(-30px, -2px);
          width: 10px;
        }

        p::before,
        p::after {
          bottom: -0.1rem;
          content: "";
          height: 1rem;
          position: absolute;
        }
      }
    }
  }
}
.chatInput {
  min-width: 80px;
  margin-left: 10px;
  margin-right: 10px;
  input {
    margin-right: 10px;
    height: 40px;
    border: thin solid;
    border-radius: 10px;
    border-color: #c9c9c9;
    padding-left: 10px;
  }
  .sendButton {
    background-color: mediumseagreen;
    border: none;
  }
}

.videoBrowserLayout {
  .videoBrowserContainer {
    display: grid;
    grid-template-columns: 7fr minmax(200px, 3fr);
    .mainContent {
      max-height: 100vh;
      min-height: 100vh;
      display: grid;
      grid-template-rows: 40px 1fr 80px;
      .videoContent {
        position: relative;
        top: 0;
        left: 0;
        display: grid;
        grid-template-columns: 1fr;
        min-height: calc(100vh - 120px);
        max-height: calc(100vh - 120px);
        background-color:black;
        .videoMainItem {
          position:relative;
          top:0;
          left:0;
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: calc(100vh - 120px);
          height: 100%;
          width: 100%;
          video.video-stream-remote {
            align-items: center;
            height: 100%;
            width: 100%;
            //object-fit: cover;
          }
          .infoWrapper {
            position: absolute;
            width: 100%;
            height:100%;
            .audioStatusIcon {
              position: absolute;
              top: 0px;
              right: 10px;
              margin-right: 20px;
              margin-top: 20px;
              background: rgba(255, 0, 0, 0.3); // Make sure this color has an opacity of less than 1
              backdrop-filter: blur(8px);
              border-radius: 25px;
              height: 50px;
              width: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              .checkIcon {
                color: white;
                font-size: 20px;
              }
            }
            .labelName {
              position: absolute;
              top: 20px;
              left: 60px;
              padding: 10px 20px;
              background: rgba(217, 217, 217, 0.75); // Make sure this color has an opacity of less than 1
              backdrop-filter: blur(8px);
              border-radius:15px;
              color: black;
            }
            .videoStatusIcon {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .videoIcon {
                font-size: 3rem;
              }
            }
          }
        }
      }

      .l1 {
        grid-template-columns: repeat(1, 1fr) !important;
        grid-template-rows:  repeat(1, 1fr)  !important;
      }
      .l2 {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-template-rows:  repeat(1, 1fr)  !important;
        height: calc(100vh - 120px);
        .videoMainItem {
          height: calc(100vh - 120px) !important;
        }
        .videoMainItemLocal {
          height: calc(100vh - 120px) !important;
        }
      }
      .l3 {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-template-rows:  repeat(2, 1fr)  !important;
        height: calc(100vh - 120px);
        .videoMainItem {
          height: calc((100vh - 120px) / 2) !important;
        }
        .videoMainItemLocal {
          height: calc((100vh -  120px)/2) !important;
        }
      }
      .l4 {
        grid-template-columns: repeat(3, 1fr) !important;
        grid-template-rows:  repeat(2, 1fr)  !important;
        .videoMainItem {
          height: calc((100vh -  120px)/2) !important;
        }
        .videoMainItemLocal {
          height: calc((100vh -  120px)/2) !important;

        }
      }
      .l5 {
        grid-template-columns: repeat(3, 1fr) !important;
        grid-template-rows:  repeat(3, 1fr)  !important;
        .videoMainItem {
          height: calc((100vh -  120px)/3) !important;
        }
        .videoMainItemLocal {
          height: calc((100vh -  120px)/2) !important;
        }
      }
      .l6 {
        grid-template-columns: repeat(4, 1fr) !important;
        grid-template-rows:  repeat(3, 1fr)  !important;
        .videoMainItem {
          height: calc((100vh -  120px)/3) !important;
        }
        .videoMainItemLocal {
          height: calc((100vh -  120px)/3) !important;
        }
      }
      .l7 {
        grid-template-columns: repeat(4, 1fr) !important;
        grid-template-rows:  repeat(4, 1fr)  !important;
        .videoMainItem {
          height: calc((100vh -  120px)/4) !important;
        }
        .videoMainItemLocal {
          height: calc((100vh -  120px)/4) !important;
        }
      }
      .l8 {
        grid-template-columns: repeat(6, 1fr) !important;
        grid-template-rows:  repeat(4, 1fr)  !important;
        .videoMainItem {
          height: calc((100vh -  120px)/4) !important;
        }
        .videoMainItemLocal {
          height: calc((100vh -  120px)/4) !important;
        }
      }
    }
    .rightSideBarBrowserContent {
      min-height: 100vh;
      .soapContainer {
        min-height: 100vh;
        .soapLayout {
          min-height: 100vh;
          background-color: #eee;
          display: grid;
          grid-template-rows: 30px 1fr 80px;
          .soapHeader {
            min-height: 30px;
            .title {
              padding-left: 10px;
              font-size: 20px;
              font-weight: bold;
            }

          }
          .documentContainer {
            margin: 10px 10px 0px 10px;
            .soapField {
              background-color: #fafafa;
              height: 100%;
              width: 100%;
              padding: 10px;
              border: none;
              border-radius: 10px;
              border-color: #c9c9c9;
              resize: none;
              /* width */
              &::-webkit-scrollbar {
                width: 8px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #cccccc;
                border-radius: 4px;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #a4a4a4;
              }
            }
          }
          .soapFooter {
            padding: 10px;
            .saved {

              font-size: 12px;
            }
          }
        }
      }
      .chatContainer {
        min-height: 100vh;
        max-height: 100vh;
        .chatLayout {
          min-height: 100vh;
          max-height: 100vh;
          background-color: #eee;
          display: grid;
          grid-template-rows: 30px 1fr 80px;
        }
      }
    }
  }
}
.onTop {
  z-index: 9000;
  position: fixed;
  bottom: 0;

  min-width: 100vw;

}
.onBack {
  position: fixed;
  bottom: 0;

  z-index: auto;
}


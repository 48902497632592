.commercial-account-detail {
  .valid-account {
    color: green;
    font-size: 20px;
  }
  .invalid-account {
    color: red;
    font-size: 20px;
  }
  .active-message {
    color: green;
    font-weight: bold;
  }
  .inactive-message {
    color: red;
    font-weight: bold;
  }
  .spinner-button {
    color: $primary-color;
    min-width: 160px;
  }
  .btn-create-account {
    min-width: 180px;
    margin-left: 20px;
  }
  .spinner-button-cancel {
    color: #DC3545FF;
  }
  .cancel-btn {
    min-width: 160px;
  }
}

.suscription-detail {

  min-height: 120px;

  .active-message {
    color: green;
    font-weight: bold;
  }
  .inactive-message {
    color: #DC3545FF;
    font-weight: bold;
  }
  .spinner-button-cancel {
    color: #DC3545FF;
  }
  .cancel-btn {
    min-width: 160px;
  }
}

.products-detail {
  .title-wrapper {
    padding-bottom: 20px;
    border-bottom: thin solid $shadow-color;
  }
}
@import 'variables';



.login-wrapper {



  & .left-half {

    .button-link {
      background-color: $primary-color;
    }

    footer {
      text-align: center;
      font-size: 10px;
    }
  }

  & span.error {
    color: red;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.43;
    font-family: Roboto, serif;
  }

  & .right-half {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: url("../../images/login.jpg") no-repeat center;
    background-clip: border-box;
  }
}


:root {
  --color-primary: 21, 101, 192;
  --color-secondary: 249, 168, 37;
}


@import 'styles/variables';
@import "styles/sidebar";
@import "styles/navbar";
@import "styles/mainpanel";
@import "styles/notfound";
@import "styles/commercialAccount";
@import 'views/citasProgramadas';
@import '~rsuite/dist/rsuite.min.css';

body {
  font-family: Roboto, serif;
  font-size: 14px;
  color: $text-color;
  overflow: hidden;
}

h1 {
  font-size: 24px;
}

.primaryColor {
  color: rgba(var(--color-primary), 1.0);
}

.grayTextColor {
  color: $text-color;
}
.lighthenGrayTextColor {
  color: Gray;
}

.breadcrumb-item+.breadcrumb-item{
  font-weight: 500;
}

.success-message {
  width: 100%;
  margin-top: 0.25rem;
  color: #19bb47;
}

.view-container {
  height: 100vh;
  padding: 0 0 0 0;
  z-index: 20;
  box-shadow: 1px 0 3px 0 $shadow-sidebar-color;
  -webkit-box-shadow: 1px 0 3px 0 $shadow-sidebar-color;
  -moz-box-shadow: 1px 0 3px 0 $shadow-sidebar-color;
  .container-fluid {
    height: calc(100vh - 105px);
    /* @media (min-width: 992px) {
      height: calc(100vh - 105px);
    }
    @media (max-width: 992px) {
      padding: 0 !important;
    } */

    display: inline-block;
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.logo-outpage {
  width: 250px;
  max-height: none;
}

.nav-tabs > li > a {
  color: rgba(var(--color-primary), 1.0) !important;
}

.link-modal span {
  font-weight: bold;
}

.bottom-right {
  position: absolute;
  right: 15px;
  bottom: 0;
}

.flex-center-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.margin-auto {
  margin: 0 auto;
}

.accordion-item h2 button {
  border: none;
  background: transparent;
  font-size: 16px;
  width: 100%;
  height: 50px;
  font-weight: bold;
  border-bottom: 1px solid #fafafc;
}

.btn-outline-primary {
  color: rgba(var(--color-primary), 1) !important;
  border-color: rgba(var(--color-primary), 1) !important;

  &:hover {
    background-color: rgba(var(--color-primary), 1) !important;
    color: white !important;
  }

  &:focus {
    background-color: rgba(var(--color-primary), 1) !important;
    color: white !important;
  }
}

.row-flex-center {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100%;
  width: 100%;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step {
  padding: 5px !important;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left, .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right {
  top: 16px !important;
}

.d-flex-mobile {
  height: calc(100vh - 40px);
  /* display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 125px); */
}

.btn-primary {
  background-color: rgba(var(--color-primary), 1) !important;
  border-color: rgba(var(--color-primary), 1) !important;
}

.btn:focus, .form-control:focus {
  border-color: rgba(var(--color-primary), 1);
  box-shadow: 0 0 0 0.2rem rgba(var(--color-primary), 0.25);
}

.color-white {
  color: white !important;
}

.container-fluid-client {
  height: 100vh;

  .video-view {
    margin: 0;
  }

  main {
    height: calc(100vh - 120px);

    .main-container article {
      height: calc(100vh - 120px);
    }
  }
}

.centered-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.admin-footer li {
  text-align: center;
}

.remote-stream {
  display: inline-block;
  float: right;
  right: 5px;

  video {
    width: 150px;
  }
}

.embed-container {
  position: sticky;
  width: 100%;
  height: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.embed-container video {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div#root {
  height: 100vh;
}

.forced-fullscreen {
  position: fixed;
  width: 100%;
  left: 0;
  height: 100%;
}

.fixed-header-avoid-layout {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.fixed-main-avoid-layout {
  position: fixed;
  width: 100%;
  top: 40px;
  left: 0;
  height: calc(100vh - 120px);
}

footer {
  padding: 0 !important;
  margin: 0 !important;

  &.shadow {
    box-shadow: inset 0px 1px 0 0 rgb(0 0 0 / 20%) !important;
  }

  ul.list-items.inline-items {
    list-style-type: none;
    margin: 5px 0px 10px -15px;

    li.list-item {
      display: inline-block;
      margin: 5px 10px 0 10px;
    }
  }
}

.terms-footer {
  width: calc(100% - 15px);
  font-size: 10px;
  position: sticky;
  bottom: 0;
  background-color: white;
  -webkit-box-shadow: 0px -0.5px 1px 0px rgba(0,0,0,0.24);
  box-shadow: 0px -0.5px 1px 0px rgba(0,0,0,0.24);
  ul {
    padding: 0 !important;
    list-style-type: none;
  }
}



.spinner {
  color: rgba(var(--color-primary), 1);
  font-size: 30px;
  animation: spin infinite 1.5s linear;
}

.spinner-button {
  color: white;
  font-size: 20px;
  animation: spin infinite 1.5s linear;
}
.spinner-button-provider {
  color: $primary-color;
  min-width: 120px;
}
.btn-create-account {
  min-width: 180px;

}

.spinner-button-create-account {
  color: $primary-color;
}

.spinner-status {
  font-size: 45px;
  position: absolute;
  top: 70px;
  center: 0px;
}

.valid-subscription {
  color: green;
  font-size: 20px;
}

.not-completed {
  color: #a1a1a1;
  font-size: 20px;
}
.invalid-subscription  {
  color: red;
  font-size: 20px;
}

.input-spinner-group {
  background-color: transparent !important;
  .input-spinner {
    color: rgba(var(--color-primary), 1);
    font-size: 20px;
    animation: spin infinite 1.5s linear;
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link-modal {
  color: #cbcbcb;

  span {
    color: rgba(var(--color-primary), 1);
  }
}


.svgStyle {
  fill: #4A4A4A;
  fill-rule: nonzero;
}

.menuActive {
  fill: rgba(var(--color-primary), 1);
}

span.error {
  color: red;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.43;
  font-family: Roboto, serif;
}

.payment-loader-content {
  min-height: 200px;
}

.mdl-card {
  width: 90% !important;
  margin: 40px auto !important;
}

.mdl-stepper-horizontal-alternative .mdl-stepper-step {
  width: auto !important;
}

.auth-layout-container {

  .auth-layout-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y:auto;
    overflow-x: hidden;
  }
}

.auth-footer {
  font-size: 10px;
  background-color: white;
  -webkit-box-shadow: 0px -0.5px 1px 0px rgba(0,0,0,0.24);
  box-shadow: 0px -0.5px 1px 0px rgba(0,0,0,0.24);
  ul {
    padding: 0;
    list-style-type: none;
  }
}

.iconAppointmentConfirm {
  width: 35px;
}
.iconAppointmentCreateConfirm {
  width: 20px;
}

.testColor1 {
  background-color: green;
}
.testColor2 {
  background-color: red;
}

/*** STEEPER MEDIA QUERIES ****/

@media (max-width: 768px) {
  .mdl-card {
    display: none;
  }
  .auth-footer {

  }
}

@media (max-height: 768px) {
  .mdl-card {
    display: none;
  }
}

/*** MENU MEDIA QUERIES ****/

@media (max-width: 575.98px) {
  .desktopMenuHide {
    visibility: visible !important;
  }
  .mobileMenuHide {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .desktopMenuHide {
    visibility: visible !important;
  }
  .mobileMenuHide {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .desktopMenuHide {
    visibility: hidden !important;
  }
  .mobileMenuHide {
    display: inline-flex !important;
  }

}

@media (min-width: 992px) {
  .desktopMenuHide {
    visibility: hidden !important;
  }
  .mobileMenuHide {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .desktopMenuHide {
    visibility: hidden !important;
  }
  .mobileMenuHide {
    display: inline-flex !important;
  }
}

/*** AUTH LAYOUT MEDIA QUERIES ****/

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

@media (max-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {  }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {  }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { }

.conceal-input {
  background: none;
  border: none;
  padding: 0;
  text-align: center;
  color: #4a4a4a;
}

input[type="checkbox"].conceal-input  {
  position: relative;
  top: 2px;
}

.btn-transparent {
  display: block;
  margin: 0 auto;
  background: transparent;
  border: none;
  color: #1565c1;
}

.icon-list {
  position: absolute;
  left: -80px;
  display: flex;
  top: 15px;
  width: 100px;
  height: 50px;
  font-size: 28px;
  color: white;
}

.icon-list-small {
  position: absolute;
  top: -35px;
  left: 10px;
  color: white;
  font-size: 16px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 3;
  color: rgba(var(--color-primary), 1);
  border-color: rgba(var(--color-primary), 1);
}


.page-item.active .page-link{
  background-color: rgba(var(--color-primary), 1);
  border-color: rgba(var(--color-primary), 1);
}

.page-item .page-link{
  color: rgba(var(--color-primary), 1);
}

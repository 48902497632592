.navbar {
  background-color: $white-bg !important;
  z-index: 1030;
  box-shadow: 0 $shadow-size 10px -2px $shadow-color !important;
  -webkit-box-shadow: 0 $shadow-size  4px -2px $shadow-color !important;
  -moz-box-shadow: 0 $shadow-size  2px -2px $shadow-color !important;
  .logo {
    height: 50px;
  }

  .user-avatar {
    width: 35px;
    height: 35px;
    margin: 0 0 0 15px;
    border: $shadow-color thin solid;
  }

  .profile-image {
    width: 90px;
    height: 90px;
    border: $shadow-color thin solid;
  }

  .language-selector {
    color: #4a4a4a;
    font-size: 14px;

  }
  .txt-change-user {
    font-size: 14px;
    color:rgba(var(--color-primary), 1);
  }
  .item-user {
    border-bottom: $shadow_color solid thin;
    padding-top: 5px;
    padding-bottom: 5px;
    .change-user-name {
      font-size: 13px;
      font-weight: bold;
      padding: 0;
      margin: 0;
    }
    .change-user-mail {
      font-size: 12px;
      padding: 0;
      margin: 0;
    }
    &:last-child {
      border-bottom: none;
    }
    .avatar {
      width: 35px;
      height: 35px;
      border: $shadow-color thin solid;
    }
  }

}

